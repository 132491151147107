<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'

import { baseNewFormConfig } from './config/new'

import { computed, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'

const routes = useRoute()
const router = useRouter()

const backPathArray = router?.options?.history?.state?.back?.split('/')
const paramId = backPathArray[backPathArray.length - 1]
const { t } = i18n.global

// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    routesName: 'ApplicationFileImport',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-application-import'),
    pageType: 'add'
  }
]

// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
// 3、将需要用到的属性解构出来
const { baseFormConfig } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

const typeMap = {
  '01': '.apk',
  '00': '.gz,.zip'
}
const type = routes?.params?.type
// 转换uploadFile的配置
baseFormConfigRef.value.formItems = baseFormConfigRef.value.formItems.map(
  (item) => {
    if (item.field === 'fileUrl') {
      item.otherOptions.acceptType = typeMap[type]
    }
    return item
  }
)

// 4、区分当前页面是否需要转化options选项
currentPageConfig.baseFormConfig = baseFormConfigRef
const configOptions = ref({})
configOptions.value = currentPageConfig

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const baseFormRef = ref('')

const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
// 7、定义被提交的方法
const bus = appContext.config.globalProperties.$bus

onMounted(() => {
  bus.on('getFileHttpResponse', (data) => {
    baseFormRef.value.formData.versionCode = data?.versionCode || ''
    baseFormRef.value.formData.versionName = data?.versionName || ''
    baseFormRef.value.formData.packageName = data?.packageName || ''

    // 当file回显的时候 没有load出versionCode versionName packageName
    const fieldsToCheck = ['versionCode', 'versionName', 'packageName']

    fieldsToCheck.forEach((fieldName) => {
      if (!data?.[fieldName]) {
        const item = baseFormConfigRef.value.formItems.find(
          (item) => item.field === fieldName
        )
        if (item) {
          item.otherOptions.disabled = false
        }
      }
    })
  })
  bus.on('removeFileClick', () => {
    baseFormRef.value.formData.versionCode = ''
    baseFormRef.value.formData.versionName = ''
    baseFormRef.value.formData.packageName = ''

    // 移除文件的时候 重新disable相关的属性
    const fieldsToDisable = ['versionCode', 'versionName', 'packageName']

    baseFormConfigRef.value.formItems.forEach((item) => {
      if (fieldsToDisable.includes(item.field)) {
        item.otherOptions.disabled = true
      }
    })
  })
})

onUnmounted(() => {
  bus.off('getFileHttpResponse')
  bus.off('removeFileClick')
})

const handleSubmit = async (data) => {
  console.log(routes.params)
  data.fileUrl = store.state.system.currentFileList[routes?.path] || ''
  data.appId = paramId
  handleMessageCommit('file/handleImportApplicationFile', data).then(() => {
    router.push(`/file/app/view/${paramId}`)
  })
}
</script>

<style scoped lang="scss">
.base-form {
  .form-item {
    padding: 5px 20px;
    .file-wrap {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #64b5fc;
      }
      .download-icon {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}
</style>
