import { ref } from 'vue'
import { setItem, getItem } from '@/utils/storage'
import { getCommonParams } from '@/service/globalParams'
import { COMMONPARAMARR } from '@/utils/constant/index'
/**
 * 用来获取基础的业务参数数据
 * @param 基础参数的key值
 * @force 是否采用强制刷新 布尔值 true/false
 */
export async function useCommonParam(param, force = false) {
  const key = 'common.param.' + param

  // option不能使用缓存，否则修改option配置的值后要重新登录才能生效
  // const local = getItem(key)
  const commonParam = ref(null)
  const commonParamArr = getItem(COMMONPARAMARR) || []
  // const isIncludeCommonParamArr = commonParamArr.includes(key)
  // if (force || local == null || local === '[]' || !isIncludeCommonParamArr) {
  // 数组得去重

  // 使用 Set 去重
  const uniqueSet = new Set(commonParamArr)
  uniqueSet.add(key) // 添加新的 key 到 Set 中，如果已存在则不会重复添加

  // 将 Set 转换回数组
  const uniqueCommonParamArr = Array.from(uniqueSet)

  // 设置去重后的数组
  setItem(COMMONPARAMARR, uniqueCommonParamArr)

  const fetchParam = async () => {
    // 调用service接口
    const res = await getCommonParams(param)
    const options = res?.data?.data || []
    setItem(key, options)
    commonParam.value = options
  }
  await fetchParam()
  return commonParam.value
  // }
  // else {
  //   commonParam.value = local
  //   return commonParam.value
  // }
}

export function useCommonParamNoAsync(param, force = false) {
  const key = 'common.param.' + param
  const local = getItem(key) || []
  const commonParam = ref(null)
  const commonParamArr = getItem(COMMONPARAMARR) || []
  const isIncludeCommonParamArr = commonParamArr.includes(key)

  if (force || local == null || local === '[]' || !isIncludeCommonParamArr) {
    commonParamArr.push(key)
    setItem(COMMONPARAMARR, commonParamArr)
    const fetchParam = async () => {
      // 调用service接口
      const res = await getCommonParams(param)
      const options = res?.data?.data || []
      setItem(key, options)
      commonParam.value = options
    }
    fetchParam()
    return commonParam.value
  } else {
    commonParam.value = local
    return commonParam.value
  }
}
